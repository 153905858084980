import { Button } from "antd";

import { API_ROOT } from 'Util/API';
import { post } from 'Util/API';

const ManifestDialogContent = ({
  status,
  error,
  onCancel,
  onPrintManifest,
  manifestId
}) => {
  const renderContent = {
    creating: () => <div>Creating manifest...</div>,
    success: () => (
      <div>
        <p>Manifest created successfully!</p>
        <Button type="primary" onClick={() => onPrintManifest(manifestId)}>
          Print Manifest
        </Button>
      </div>
    ),
    error: () => <div style={{ color: 'red' }}>{error}</div>
  };

  return renderContent[status]?.() || null;
};

export const onOpenManifestModal = async ({
  orderIds,
  setIsLoading,
  setModalState,
  modalState,
  companyId,
  customerId
}) => {
  const updateModalContent = (status, error = '', manifestId = null) => {
    setModalState(prev => ({
      ...prev,
      modal: {
        ...prev.modal,
        dialogContent: () => (
          <ManifestDialogContent
            status={status}
            error={error}
            manifestId={manifestId}
            onCancel={() => setModalState({ ...modalState, isModalVisible: false })}
            onPrintManifest={(manifestId) => {
              window.open(`${API_ROOT}manifest/${manifestId}/print`);
            }}
          />
        )
      }
    }));
  };

  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      title: 'Create Manifest',
      dialogContent: () => (
        <ManifestDialogContent
          status="creating"
          onCancel={() => setModalState({ ...modalState, isModalVisible: false })}
        />
      )
    }
  });
  
  try {
    const createOrder = await post('manifest', {
      orderIds,
      customerId,
    });
    if (createOrder.status === 200) {
      const manifestId = createOrder.data.id;
      updateModalContent('success', '', manifestId);
    } else {
      updateModalContent('error', createOrder || 'Failed to create manifest');
    }
  } catch (err) {
    updateModalContent('error', err.message || 'Failed to create manifest');
  }
};
