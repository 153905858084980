import { Button, message } from "antd";
import React, { useState } from "react";
import { API_ROOT } from 'Util/API';
import { post } from 'Util/API';
import moment from "moment";

const ordersTableColumns = (props) => {
  const { state: { global }, refreshData } = props;

  const handleCancel = async (record) => {
    const button = document.querySelector(`button[data-manifest-id="${record.id}"]`);
    if (button) {
      button.disabled = true;
    }

    try {
      const response = await post(`manifest/${record.id}/cancel`);
      
      if (response.status === 200) {
        message.success('Manifest cancelled successfully');
        // Refresh the table data
        if (typeof refreshData === 'function') {
          refreshData();
        }
      } else {
        message.error(response.message || 'Failed to cancel manifest');
        // Re-enable the button on failure
        if (button) {
          button.disabled = false;
        }
      }
    } catch (error) {
      message.error(error.message || 'An error occurred while cancelling the manifest');
      // Re-enable the button on error
      if (button) {
        button.disabled = false;
      }
    }
  };

  return [
    {
      title: 'Manifest ID',
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (id, data) => {
        return (
          <div>
            #{id}
          </div>
        );
      }
    },
    {
      title: 'Courier',
      dataIndex: "courier",
      key: "courier",
      width: 50,
      render: (text, record) => {
        return (
          <div>
            {record.service?.name || 'Unknown'}
          </div>
        );
      }
    },
    {
      title: 'Sender',
      dataIndex: "sender",
      key: "sender",
      width: 150,
      render: (text, record) => {
        const address = [];
        if (record.sender.unitNo) address.push(record.sender.unitNo);
        if (record.sender.address1) address.push(record.sender.address1);
        if (record.sender.address2) address.push(record.sender.address2);
        if (record.sender.city) address.push(record.sender.city);
        if (record.sender.state) address.push(record.sender.state);
        if (record.sender.postcode) address.push(record.sender.postcode);
        if (record.sender.country) address.push(record.sender.country);
        return (
          <div>
            <p>{record.sender?.name}</p>
            <p>{address.join(' ')}</p>
          </div>
        );
      }
    },
    {
      title: 'Created Date',
      dataIndex: "createdAt",
      key: "createdAt",
      width: 50,
      render: (text, record) => {
        return (
          <div>
            {moment(record.createdAt).format("Do MMM YYYY")}<br />
            <small>
              {moment(record.createdAt).format("h:mm a")}
            </small>
          </div>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: "action",
      key: "action",
      width: 30,
      render: (text, record) => {
        return (
          <div>
            <p>
              {record.status !== 9 && (
                <Button
                  type="primary"
                  target="_blank"
                  onClick={() => window.open(`${API_ROOT}manifest/${record.id}/print`)}
                >
                  Print
                </Button>
              )}
            </p>
            <p>
              {record.status === 0 && (
                <Button
                  data-manifest-id={record.id}
                  onClick={() => handleCancel(record)}
                >
                  Cancel
                </Button>
              )}
            </p>
          </div>
        );
      }
    },
  ];
};

export default ordersTableColumns;
