import React, { useState, useEffect, useContext } from "react";
import { Layout, message } from "antd";
import PageWrapper from 'Components/PageWrapper';

import OrdersHeader from "./OrdersHeader";
import ordersTableColumn from './ordersTableColumns';
import OrdersTableContent from "./OrdersTableContent";

import { get } from 'Util/API';

import { GlobalContext } from "Store/store";

import ServerSidePaginationTable from 'Components/table/ServerSidePaginationTable';

const { Content } = Layout;

const initialVal = {
  tableRowsData: {
    data: [],
    limit: 10,
    page: 1,
    pageTotal: 0,
    rowTotal: 0,
    sortOrder: 'asc',
    sortField: 'createdAt',
    status: '0',
  }
}

const Manifest = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableRowsData, setTableRowsData] = useState(initialVal.tableRowsData);
  const { page, limit, orderBy, sortBy, status, keyword, rowTotal, data: rowsData } = tableRowsData;
  const [allOrdersIds, setAllOrderIds] = useState([]);

  const [state] = useContext(GlobalContext);
  const { customerDetails } = state.global;

  useEffect(() => {
    getTableRows()
  }, [limit, page, orderBy, sortBy, keyword, status]);

  useEffect(() => {
    !rowsData && getTableRows()
  }, [rowsData]);

  const getTableRows = async (data) => {
    setIsLoading(true);

    const dataCopy = { ...tableRowsData };
    dataCopy.page = data?.page || dataCopy.page;

    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    delete dataCopy.dateFrom;
    delete dataCopy.dateTo;

    const params = new URLSearchParams();
    params.set('customerId', customerDetails?.id);
    Object.keys(dataCopy).forEach((key, i) => {
      params.set(key, dataCopy[key]);
    });

    const orderResponse = await get(`manifest?` + params.toString());
    if (orderResponse.status === 200) {
      const { data, ...rest } = orderResponse.data
      const ordersWithKey = data.map((item, i) => ({
        key: dataCopy.page > 1 ? dataCopy.page - 1 + i.toString() : i.toString(),
        ...item
      }));
      setTableRowsData({
        ...tableRowsData,
        page: dataCopy.page,
        data: ordersWithKey,
        ...rest
      })
    } else message.error(orderResponse);
    setIsLoading(false);
  }


  const getAllRows = async (props) => {
    setIsLoading(true);

    const getAll = await get(`manifest`);
    if (getAll.status === 200) {
      const ordersIds = getAll.data.data.map((item) => item.id);
      setAllOrderIds(ordersIds);
    } else message.error(getAll);
    setIsLoading(false);
  }

  const generateColumn = (props) => ordersTableColumn({ 
    ...props,
    refreshData: getTableRows // Pass the refresh function
  });

  return (
    <PageWrapper loading={isLoading} currentPath={props.location.pathname} isCollapseSidebar={false}>
      <Layout>
        <Content className="ui-right-content form">
          <OrdersHeader ordersTableData={tableRowsData} />
          <div style={{ paddingTop: 30 }}>
            <div
              className="whiteBColor"
              style={{
                height: "100%",
                border: "1px solid #cfdae6",
                position: "relative"
              }}
            >

              <ServerSidePaginationTable
                data={tableRowsData}
                setData={setTableRowsData}
                getAllRowsIds={getAllRows}
                allRowsIds={allOrdersIds}
                setAllRowsIds={setAllOrderIds}
                location={props.location}
                history={props.history}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                generateColumn={(props) => generateColumn(props)}
                itemRenderer={OrdersTableContent}
                defaultActiveKey='0'
                tab={[
                  { name: 'Pending', key: '0' },
                  { name: 'In Progress', key: '1' },
                  { name: 'Cancelled', key: '9' },
                ]}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </PageWrapper>
  );
};

export default Manifest;
